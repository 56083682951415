import React, { Component } from 'react';
import Layout from '../components/layout'
import Footer from '../components/footer'
import { graphql } from "gatsby"
import Img from 'gatsby-image'
import IconText from '../components/iconText'
import SEO from '../components/seo'

import { Row, Col } from 'antd'


class Cavalier extends Component {
    constructor(props){
        super(props);
        this.state = {
            navbar: 'top',
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }
    
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        if(window.scrollY > 100){
            this.setState({navbar: 'no-top'})
        } else {
            this.setState({navbar: 'top'})
        }
    };

    render() {
        return (
            <Layout onScroll={this.handleScroll} position={this.state.navbar} currentPage='cavalier'>
                <SEO title="Shalee, l'agent digital équestre" keywords={[`Shalee`,`Application`,`Mobile`,`Cheval`,`Chevaux`,`Club`,`Equestre`,`Equitation`,`Cavalier`]}/>
                <div style={{paddingTop: '100px'}}>
                    <section className="bg-dark">
                        <Row className="container"  type="flex" justify="space-around" align="middle" gutter={50}>
                            <Col xs={34} sm={34} md={12} lg={12} xl={12} className="second">
                                <div style={{maxWidth:"300px", margin:'auto'}}>
                                    <Img fluid={this.props.data.imageIphoneCavalier.childImageSharp.fluid} style={{minWidth: '250px'}}/>
                                </div>
                            </Col>
                            <Col xs={34} sm={34} md={12} lg={12} xl={12} className="first">
                                <h1 className="title" >Deviens un cavalier moderne</h1>
                                <p style={{fontSize:"16px"}}>Que tu sois confirmé ou débutant, on te facilite la vie de tous les jours. Prend désormais en main tous les services de ton club avec Shalee.</p>
                                <br/>
                                <IconText icon="calendar" title="COURS, BALADES, ENTRAÎNEMENTS ET ÉVÉNEMENTS"
                                    text="Tu souhaites participer à un événement équestre, à un concours ou réserver un cours? Rien de plus simple. Recherche sur Shalee."/>
                                <IconText icon="user-add" title="SUIS TES AMIS"
                                    text="Inscris-toi directement aux mêmes sessions que tes amis pour partager ensembles des moments conviviaux."/>
                                <IconText icon="credit-card" title="PAYE EN LIGNE"
                                    text="Réserve directement ta session, prend ta licence ou règle ta cotisation directement sur Shalee."/>
                            </Col>
                        </Row>
                    </section>
                    <Footer/>
                </div>
            </Layout>
        );
    }
}

export default Cavalier;

export const pageQuery = graphql`
  query {
    imageIphoneCavalier: file(relativePath: { eq: "cavaliers_iphoneX_shalee.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`

