import React from 'react';
import { Row, Col, Icon } from 'antd'


const IconText = ({icon, title, text}) => {
    return (
    <div>
        <Row>
            <Col xs={34} sm={34} md={8} lg={8} xl={8}>
                <Icon type={icon} style={{fontSize:"40px", padding:"20px", color:"#004A8E"}}/>
            </Col>
            <Col xs={34} sm={34} md={16} lg={16} xl={16}>
                <h3 style={{textAlign:"left"}}>{title}</h3>
                <p style={{fontSize:"16px"}}>{text}</p>
            </Col>
        </Row>
    </div>
    );
};

export default IconText;